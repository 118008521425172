import axios from 'axios';

document.addEventListener('DOMContentLoaded', function() {
    let i;
    // notifications dropdown toggles
    const notificationElement = document.querySelectorAll('.bell');
    const notificationWindow = document.querySelector('.notifications');
    const notificationCloseButton = document.querySelector('.notifications__close');

    if (notificationElement.length && notificationWindow) {
        for (i = 0; i < notificationElement.length; ++i) {
            notificationElement[i].addEventListener('click', function(e) {
                notificationWindow.classList.toggle('notifications--active');

                e.preventDefault();
                e.stopPropagation();
            });
        }
    }

    if (notificationCloseButton && notificationWindow) {
        notificationCloseButton.addEventListener('click', function(e) {
            notificationWindow.classList.toggle('notifications--active');
            e.preventDefault();
        });
    }

    // dismiss notification listener
    const notifications = document.querySelectorAll('.notification');
    for (i = 0; i < notifications.length; ++i) {
        notifications[i].addEventListener('click', markNotificationAsRead);
    }

    // dismiss all notifications listener
    const markAllButton = document.querySelector('.mark-all');
    if (markAllButton) {
        markAllButton.addEventListener('click', markAllAsRead);
    }

    function markNotificationAsRead(e: any) {
        // return if already dismissed
        if (!e.target.classList.contains('notification__indicator--active')) {
            return;
        }

        e.target.classList.remove('notification__indicator--active');

        if (document.querySelectorAll('.notification__indicator--active').length === 0) {
            removeRedDot();
        }

        // send ajax request to update database
        const notificationId = e.target.parentNode.id;
        const url = `/api/account/notifications/${notificationId}`;

        axios.patch(url, {dismiss: true});
    }

    function markAllAsRead() {
        removeRedDot();

        let i;
        const dismissed = document.querySelectorAll('.notification__indicator--active');
        for (i = 0; i < dismissed.length; ++i) {
            dismissed[i].classList.remove('notification__indicator--active');
        }

        // send request
        const url = '/api/account/notifications';
        axios.patch(url);
    }

    function removeRedDot() {
        const notificationCountBadge = document.querySelectorAll('.bell--has-unread');

        for (let i = 0; i < notificationCountBadge.length; ++i) {
            notificationCountBadge[i].classList.remove('bell--has-unread');
        }
    }
});

// Click off notification window to close
document.addEventListener('click', (e) => {
    const notificationWindow = document.querySelector('.notifications');
    const targetElement = e.target as Element;

    // Click off for garage and new garage desktop
    if (notificationWindow && !notificationWindow.contains(targetElement)) {
        notificationWindow.classList.remove('notifications--active');
    }
});
