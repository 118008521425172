let adBlockEnabled = 0;

async function detectAdBlock() {
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    try {
        await fetch(new Request(googleAdUrl)).catch((_) => adBlockEnabled = 1);
    } catch (e) {
        adBlockEnabled = 2;
    } finally {
        console.debug(`AdBlock Enabled: ${adBlockEnabled}`);
    }
}

detectAdBlock();

window.sendAnalyticsEvent('AdBlock Tracking', 'Status', adBlockEnabled > 0 ? 'Enabled' : 'Disabled');
