document.addEventListener('DOMContentLoaded', function() {
    const myMenu = document.querySelector('.main-nav');
    const openMenu = document.querySelector('.header__menu');

    function toggleNodeClass(node: Element, className: string) {
        if (!node.classList.contains(className)) {
            node.classList.add(className);
        } else {
            node.classList.remove(className);
        }
    }

    function toggleClassMenu() {
        if (!myMenu) {
            return;
        }
        myMenu.classList.add('main-nav--animatable');
        toggleNodeClass(myMenu, 'main-nav--opened');
        toggleNodeClass(document.body, 'main-nav__body--fixed');
    }

    function OnTransitionEnd() {
        if (!myMenu) {
            return;
        }
        myMenu.classList.remove('main-nav--animatable');
    }

    if (myMenu) {
        myMenu.addEventListener('transitionend', OnTransitionEnd, false);
        myMenu.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            if (target.classList.contains('app-menu')) {
                toggleClassMenu();
            }
        });
    }
    if (openMenu) {
        openMenu.addEventListener('click', toggleClassMenu, false);
    }

    // expand submenu
    const itemsWithSubmenus = document.getElementsByClassName('main-nav__item--has-children');
    for (let i = 0; i < itemsWithSubmenus.length; i++) {
        const item = itemsWithSubmenus.item(i);
        if (!item) {
            break;
        }

        item.addEventListener('click', function(e) {
            const currentLink = e.target as Element;
            const currentLinkWasOpened = currentLink.classList.contains('main-nav__item--open');
            if (currentLink.classList.contains('main-nav__link') ||
                currentLink.closest('.main-nav__link')
            ) {
                return true;
            }

            for (let i = 0; i < itemsWithSubmenus.length; i++) {
                const item1 = itemsWithSubmenus.item(i);
                if (!item1) {
                    break;
                }
                item1.classList.remove('main-nav__item--open');
            }
            if (!currentLinkWasOpened) {
                currentLink.classList.add('main-nav__item--open');
            }

            e.preventDefault();
        });
    }
});
