import {App} from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';

const ServerConfig = require('../config/server');

let bugsnagInitialised = false;

export const initialiseBugsnag = () => {
    if (bugsnagInitialised) {
        return;
    }

    Bugsnag.start({
        apiKey: ServerConfig.bugsnagApi,
        plugins: [new BugsnagPluginVue()],
        releaseStage: ServerConfig.env,
        appVersion: ServerConfig.buildNumber,
        enabledReleaseStages: ['production', 'staging', 'testing', 'local'],
    });

    BugsnagPerformance.start({
        apiKey: ServerConfig.bugsnagApi,
        releaseStage: ServerConfig.env,
        appVersion: ServerConfig.buildNumber,
        enabledReleaseStages: ['production', 'staging', 'testing', 'local'],
    });

    bugsnagInitialised = true;
};

export const connectVue = (app: App) => {
    initialiseBugsnag();

    const plugin = Bugsnag.getPlugin('vue');

    if (plugin) {
        app.use(plugin);
    }
};
