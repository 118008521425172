const tooltipIcons = document.getElementsByClassName('tooltip__icon');
for (let i = 0; i < tooltipIcons.length; i++) {
    const item = tooltipIcons.item(i);

    if (!item) {
        break;
    }

    item.addEventListener('click', function(e) {
        const tooltipIcon = e.target as Element;
        const tooltipContainer = tooltipIcon.parentNode as Element;

        if (!tooltipContainer) {
            return;
        }

        if (tooltipContainer.classList.contains('tooltip--opened')) {
            tooltipContainer.classList.remove('tooltip--opened');
        } else {
            tooltipContainer.classList.add('tooltip--opened');
        }

        e.stopPropagation();
    });
}
