import {initialiseBugsnag} from './modules/configureBugsnag';

initialiseBugsnag();

require('./global/nav');
require('./global/tooltip');
require('./global/notifications');
require('./global/accordion');
require('./global/adblock');

